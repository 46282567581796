import { storyblokEditable } from '@storyblok/react'

import { Button } from 'common/UI'
import { getValidIcon } from 'common/UI/Icon'
import { IllustratedIcon } from 'common/UI/IllustratedIcon'
import { ClinicProcessStoryblok } from 'common/types'
import { getAnchorFromCmsLink } from 'common/utils/content'

import { ClinicSectionHeader } from './ClinicSectionHeader'

type Props = {
  block: ClinicProcessStoryblok
}

export const ClinicProcess = ({ block, ...props }: Props): JSX.Element => {
  return (
    <div {...props}>
      <ClinicSectionHeader
        block={{
          variant: 'default',
          title: block.title,
          description: block.description,
          tag: block.tag,
          _uid: block._uid,
          component: 'clinic-section-header',
        }}
      />

      <div className="w-full max-w-screen-desktop overflow-hidden mx-auto px-5 lg:px-20">
        <div
          className="grid grid-cols-1 gap-5 pb-5 md:gap-10 md:grid-cols-2 lg:grid-cols-4 lg:pb-15"
          {...storyblokEditable(block)}
        >
          {block.entry.map((item, i) => {
            const { _uid, icon, title, description, call_to_action } = item

            const illustratedIcon = icon?.[0].icon
            const illustratedIconRectangleColor = icon?.[0].rectangle_color

            return (
              <div key={_uid} {...storyblokEditable(item)}>
                <div className="flex gap-5 md:flex-col overflow-hidden">
                  {illustratedIcon && (
                    <div className="flex flex-col relative">
                      <div className="bg-background z-20 pb-5">
                        <IllustratedIcon
                          icon={illustratedIcon}
                          rectangleColor={
                            illustratedIconRectangleColor || 'blue'
                          }
                        />
                      </div>

                      <span className="z-10 mt-5 h-full top-[30px] w-1 border-r border-dashed border-foreground/80 absolute left-1/2 -translate-x-full md:hidden" />
                    </div>
                  )}

                  <div className="flex flex-col gap-5">
                    <div className="space-y-1">
                      <p className="text-twenty font-regular">{title}</p>
                      <p className="text-eighteen">{description}</p>
                    </div>

                    {call_to_action?.[0] &&
                      (() => {
                        const { icon, label, alternative_text, link } =
                          call_to_action[0]

                        const typedIcon = getValidIcon(icon)

                        const { href, target, rel } = getAnchorFromCmsLink(link)

                        return (
                          <Button
                            as="a"
                            key={i}
                            rel={rel}
                            href={href}
                            target={target}
                            icon={typedIcon}
                            alternativeText={alternative_text}
                            variant={i === 0 ? 'primary' : 'outline'}
                          >
                            {label}
                          </Button>
                        )
                      })()}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
